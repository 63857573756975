import React from "react";
import ProSourceForm from "../../components/ProSourceForm";
import { Nav, Modal, Form, Button, Row, Col } from "react-bootstrap";
import {
  WarningAlert,
  Label,
  RequiredAsterisk,
  StoreHasDelivery,
  storeHasCurbside,
  storeHasMealPlan,
  storeHasPickupActive,
  CountActive,
} from "../../components/Base";
import { GetSubmitClassList } from "../../helpers";
import OrderDateDropdown from "./OrderDateDropdown";
import OrderTimeDropDown from "./OrderTimeDropdown";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { DANGER_SWAL_COLOR } from "../../constants";
import moment from "moment";
const MySwal = withReactContent(Swal);

export default class OrderDetailsFormNew extends ProSourceForm {
  constructor(props) {
    super(props);

    const { values = {}, brand = {} } = this.props,
      { stores = [] } = brand;
    // console.log(stores);

    let {
      store = "",
      selectedUrl = "",
      order_type = "",
      order_subtype = "",
      meal_plan_type = "",
      order_date = moment(new Date()).format("YYYY-MM-DD"),
      order_time = "asap_order_time",
    } = values;
    let key = "asap",
      selected_store = {},
      isStoreOpenForOrders = true,
      isStoreHoursOpen = true,
      isStoreHasAsapSlot = false,
      isStoreHasPreOrderSlots = false,
      isAsap = true,
      mountOrderDateDropdown = false,
      mountOrderTimeDropdown = false;

    if (store === "") {
      if (stores.length === 1) {
        selected_store = stores[0];
        store = selected_store._id;
        selectedUrl = selected_store.store_url;
        isStoreOpenForOrders = selected_store.is_store_open_for_orders;
        isStoreHoursOpen = selected_store.is_store_hours_open;
        isStoreHasAsapSlot = selected_store.is_store_has_asap_slot;
        isStoreHasPreOrderSlots = selected_store.is_store_has_preorder_slots;

        let { defaultOrderType, defaultSubtype, defaultPlanType, defaultKey } =
          checkStoreDefaults(selected_store);
        order_type = defaultOrderType;
        order_subtype = defaultSubtype;
        meal_plan_type = defaultPlanType;
        key = defaultKey;
      }
    }
    if (key === "preorder") {
      isAsap = false;
      order_date = isStoreOpenForOrders ? order_date : "";
      order_time =
        isStoreOpenForOrders && order_time !== "asap_order_time"
          ? order_time
          : "";
      mountOrderDateDropdown = true;
      mountOrderTimeDropdown = true;
    }
    this.state = {
      values: {
        store,
        selectedUrl,
        order_date,
        order_time,
        order_type,
        order_subtype,
        meal_plan_type,
      },
      errors: {},
      touched: {},
      isSubmitting: false,
      date_options: [],
      time_options: [],
      selected_store,
      store_time_options: {},
      isStoreOpenForOrders,
      isStoreHoursOpen,
      isStoreHasAsapSlot,
      isStoreHasPreOrderSlots,
      mountOrderDateDropdown,
      mountOrderTimeDropdown,
      isAsap,
      key,
      redirectTo: "",
      isHover: false,
    };

    this.validate = {
      // required: (order_type === "delivery") ? ["delivery_address", "order_date", "order_time"] : ["order_date", "order_time"]
      required: ["order_date", "order_time", "store"],
    };
  }

  onChange = (e) => {
    let name = e.target.name,
      value = e.currentTarget.value;
    let { values = {} } = this.state;

    if (name === "order_date") {
      // values.order_time = "asap_order_time";
      this.setState({ mountOrderTimeDropdown: false, values });
      setTimeout(() => {
        this.setState({ mountOrderTimeDropdown: true });
      }, 50);
    }
    if (name === "store") {
      if (value !== "") {
        const { stores = [] } = this.props.brand;
        const { order_date, order_time, isOpenForOrders } = values;
        const selected_store = stores.find((s) => s._id === value);
        values.selectedUrl = `${selected_store.store_url}`;
        let { defaultOrderType, defaultSubtype, defaultPlanType, defaultKey } =
          checkStoreDefaults(selected_store);
        this.setState(
          {
            mountOrderDateDropdown: false,
            mountOrderTimeDropdown: false,
            values: {
              ...values,
              order_type: defaultOrderType,
              order_subtype: defaultSubtype,
              meal_plan_type: defaultPlanType,
              order_time:
                defaultKey === "asap" ? "asap_order_time" : order_time,
            },
            key: defaultKey,
            selected_store,
            isStoreOpenForOrders: selected_store.is_store_open_for_orders,
            isStoreHoursOpen: selected_store.is_store_hours_open,
            isStoreHasAsapSlot: selected_store.is_store_has_asap_slot,
            isStoreHasPreOrderSlots: selected_store.is_store_has_preorder_slots,
          },
          () => {
            if (defaultKey === "preorder") {
              this.setState({
                isAsap: false,
                key: "preorder",
                values: {
                  ...values,
                  order_type: defaultOrderType,
                  order_subtype: defaultSubtype,
                  meal_plan_type: defaultPlanType,
                  order_date: isOpenForOrders ? order_date : "",
                  order_time:
                    isOpenForOrders && order_time !== "asap_order_time"
                      ? order_time
                      : "",
                },
                mountOrderDateDropdown: true,
                mountOrderTimeDropdown: true,
              });
            } else if (defaultKey === "asap") {
              this.setState({
                isAsap: true,
                key: "asap",
                values: {
                  ...values,
                  order_type: defaultOrderType,
                  order_subtype: defaultSubtype,
                  meal_plan_type: defaultPlanType,
                  order_date: moment(new Date()).format("YYYY-MM-DD"),
                  order_time: "asap_order_time",
                },
              });
            }
          },
        );
      } else {
        values.order_date = "";
        values.order_time = "";
        this.setState({
          mountOrderDateDropdown: false,
          mountOrderTimeDropdown: false,
          values,
          selected_store: {},
        });
      }
    }
  };

  onChangeAsap = (value) => {
    const { values } = this.state;
    if (value === "asap") {
      this.setState({
        values: {
          ...values,
          order_time: "asap_order_time",
          order_date: moment(new Date()).format("YYYY-MM-DD"),
        },
        isAsap: true,
        key: "asap",
        mountOrderDateDropdown: false,
        mountOrderTimeDropdown: false,
      });
    } else if (value === "preorder") {
      this.setState({
        values: { ...values, order_time: "" },
        // values,
        isAsap: false,
        key: "preorder",
        mountOrderDateDropdown: true,
        mountOrderTimeDropdown: true,
      });
    }
  };

  async handleOnSubmit(event) {
    event.preventDefault(); // prevents the normal submit event which is a post request

    await this.setAllFieldsToTouched();
    const { values } = this.state;
    const { order_subtype } = values;
    if (this.isFormOk()) {
      this.setSubmitting();
      this.onSubmit && this.onSubmit(this.state.values, this.setSubmitting);
    } else {
      if (order_subtype === "meal_plan") {
        this.setSubmitting();
        this.onSubmit && this.onSubmit(this.state.values, this.setSubmitting);
      }
    }
  }
  onSubmit = (values, setSubmitting) => {
    // console.log(values);
    if (!values.selectedUrl) {
      const { stores = [] } = this.props.brand,
        { selected_store = {} } = values;
      if (Object.keys(selected_store).length > 0) {
        values.selectedUrl = `${selected_store.store_url}`;
      } else {
        values.selected_store = stores[0];
        if (
          values.order_subtype === "meal_plan" &&
          values.selected_store.isMealPlanActive === true
        ) {
          values.selectedUrl = `${stores[0].store_url}`;
        } else {
          values.selectedUrl = `${stores[0].store_url}`;
          MySwal.fire({
            icon: "error",
            title: "Ooops!",
            text: "Meal Plan fulfillment is not turned on in this store. If you wish to continue ordering, please contact the store.",
            confirmButtonColor: DANGER_SWAL_COLOR,
            confirmButtonText: "Back to Home",
          }).then(() => {
            window.location.href = "/";
          });
        }
      }
    }
    this.props.onSubmit(values, setSubmitting);
  };

  handleOnClick3DayMealPlan = () => {
    const values = this.state.values;
    values.order_type = "delivery";
    values.order_subtype = "meal_plan";
    values.meal_plan_type = "3DayMealPlan";
    this.setState({ values });
  };

  handleOnClick5DayMealPlan = () => {
    const values = this.state.values;
    values.order_type = "delivery";
    values.order_subtype = "meal_plan";
    values.meal_plan_type = "5DayMealPlan";
    this.setState({ values });
  };

  handleOnMouseEnter = () => {
    this.setState({ isHover: true });
  };

  handleOnMouseLeave = () => {
    this.setState({ isHover: false });
  };

  render() {
    // console.log("order subtype: ", this.props.order_subtype)
    const {
      show = false,
      onHide,
      brand = {},
      brand_url = "",
      website_theme = {},
      buttonStyle = {},
    } = this.props;
    const { stores = [] } = brand;
    const {
      values = {},
      errors = {},
      selected_store = {},
      mountOrderTimeDropdown = false,
      mountOrderDateDropdown = false,
      key = "asap",
    } = this.state;
    // console.log("orderDetails", this.state);

    // const actions = { handleFeedbackError: this.handleFeedbackError, isTouched: this.isTouched }
    const actions = {
      handleOnClickOrderType: (orderType, orderSubtype) => {
        const { values, selected_store } = this.state;
        values.order_type = orderType;
        values.order_subtype = orderSubtype;
        if (orderSubtype === "meal_plan") {
          this.setState({ values, key: "preorder" });
        } else {
          let { defaultKey } = checkStoreDefaults(selected_store);
          this.setState({ values });
          this.onChangeAsap(defaultKey);
        }
      },
    };

    const inputActions = {
      onChange: this.handleOnChange,
      onBlur: this.handleOnBlur,
    };
    const hasStoreSelected =
      Object.keys(this.state.selected_store).length !== 0;
    const { button_text_color = "" } = website_theme;
    const isOpenForOrders = this.state.isStoreOpenForOrders;
    const isStoreHoursOpen = this.state.isStoreHoursOpen;
    const isStoreHasAsapSlot = this.state.isStoreHasAsapSlot;
    const isPreOrderDisabled = !this.state.isStoreHasPreOrderSlots;
    const isAsapDisabled = storeAsapDisabledCheck();
    const isStoreCanServed = storeCanServedCheck();

    return (
      <Modal show={show} onHide={onHide} centered>
        <Modal.Header>
          <Modal.Title>{this.props.title}</Modal.Title>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={onHide}
          >
            <i aria-hidden="true" className="ki ki-close"></i>
          </button>
        </Modal.Header>
        <Form onSubmit={this.handleOnSubmit}>
          <Modal.Body>
            <DisplayErrorMessage isOpenForOrders={isOpenForOrders} />
            <Form.Group>
              <Label
                text={
                  <>
                    <span className="text-capitalize">store location</span>{" "}
                    {stores.length > 1 && <RequiredAsterisk />}
                  </>
                }
              />
              {stores.length > 1 ? (
                <>
                  <Form.Control
                    as="select"
                    value={values["store"]}
                    name="store"
                    {...inputActions}
                  >
                    <option value="">Select store location</option>
                    {stores.map((store, index) => {
                      const noDelivery =
                        !store.isDeliveryActive && !StoreHasDelivery(store);
                      const noCurbside =
                        !store.isCurbsideActive && !storeHasCurbside(store);
                      const noMealPlan =
                        !store.isMealPlanActive && !storeHasMealPlan(store);
                      const noPickup =
                        !store.isPickupActive && !storeHasPickupActive(store);
                      return (
                        <option
                          value={store._id}
                          key={index}
                          disabled={
                            noDelivery && noCurbside && noMealPlan && noPickup
                          }
                        >
                          {store.name}
                          {noDelivery &&
                            noCurbside &&
                            noMealPlan &&
                            noPickup &&
                            "(Ordering Unavailable)"}{" "}
                        </option>
                      );
                    })}
                  </Form.Control>
                  <div className="fv-plugins-message-container">
                    {errors.hasOwnProperty("store") ? (
                      <div className="fv-help-block">{errors["store"]}</div>
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              ) : (
                <p>{stores.length > 0 ? stores[0].name : ""}</p>
              )}
            </Form.Group>

            {isStoreCanServed ? (
              <>
                <Form.Group>
                  <Label
                    text={
                      <>
                        <span className="text-capitalize">Order Type</span>{" "}
                        {stores.length > 1 && <RequiredAsterisk />}
                      </>
                    }
                  />
                  <div className="d-flex flex-row flex-wrap justify-content-center">
                    {(Object.keys(selected_store).length === 0 ||
                      storeHasPickupActive(selected_store)) && (
                      <Button
                        style={{
                          height: "70px",
                          width: "90px",
                          borderColor: "transparent",
                          backgroundColor:
                            this.state.values.order_type === "pickup" &&
                            this.state.values.order_subtype === "pickup"
                              ? buttonStyle.backgroundColor
                              : "lightgray",
                          color:
                            this.state.values.order_type === "pickup" &&
                            this.state.values.order_subtype === "pickup"
                              ? buttonStyle.color
                              : "",
                          fontSize: "0.9rem",
                        }}
                        className="flex-fill align-items-center justify-content-center btn mx-1 my-5 pa-0"
                        onClick={() =>
                          actions.handleOnClickOrderType("pickup", "pickup")
                        }
                        disabled={Object.keys(selected_store).length === 0}
                      >
                        <i
                          className="fas fa-shopping-bag"
                          style={{
                            color:
                              this.state.values.order_type === "pickup" &&
                              this.state.values.order_subtype === "pickup"
                                ? buttonStyle.color
                                : "",
                            fontSize: "0.9rem",
                          }}
                        ></i>
                        <div> Click and Collect </div>
                      </Button>
                    )}

                    {(Object.keys(selected_store).length === 0 ||
                      StoreHasDelivery(selected_store)) && (
                      <Button
                        style={{
                          height: "70px",
                          width: "90px",
                          borderColor: "transparent",
                          backgroundColor:
                            this.state.values.order_type === "delivery" &&
                            this.state.values.order_subtype === "delivery"
                              ? buttonStyle.backgroundColor
                              : "lightgray",
                          color:
                            this.state.values.order_type === "delivery" &&
                            this.state.values.order_subtype === "delivery"
                              ? buttonStyle.color
                              : "",
                          fontSize: "0.9rem",
                        }}
                        className="flex-fill align-items-center justify-content-center btn mx-1 my-5 pa-0"
                        onClick={() =>
                          actions.handleOnClickOrderType("delivery", "delivery")
                        }
                        disabled={Object.keys(selected_store).length === 0}
                      >
                        <i
                          className="fas fa-motorcycle"
                          style={{
                            color:
                              this.state.values.order_type === "delivery" &&
                              this.state.values.order_subtype === "delivery"
                                ? buttonStyle.color
                                : "",
                            fontSize: "0.9rem",
                          }}
                        ></i>
                        <div> Delivery </div>
                      </Button>
                    )}

                    {(Object.keys(selected_store).length === 0 ||
                      storeHasCurbside(selected_store)) && (
                      <Button
                        style={{
                          height: "70px",
                          width: "90px",
                          borderColor: "transparent",
                          backgroundColor:
                            this.state.values.order_type === "pickup" &&
                            this.state.values.order_subtype ===
                              "curbside_pickup"
                              ? buttonStyle.backgroundColor
                              : "lightgray",
                          color:
                            this.state.values.order_type === "pickup" &&
                            this.state.values.order_subtype ===
                              "curbside_pickup"
                              ? buttonStyle.color
                              : "",
                          fontSize: "0.9rem",
                        }}
                        className="flex-fill align-items-center justify-content-center btn mx-1 my-5 pa-0"
                        onClick={() =>
                          actions.handleOnClickOrderType(
                            "pickup",
                            "curbside_pickup",
                          )
                        }
                        disabled={Object.keys(selected_store).length === 0}
                      >
                        <i
                          className="fas fa-shopping-bag"
                          style={{
                            color:
                              this.state.values.order_type === "pickup" &&
                              this.state.values.order_subtype ===
                                "curbside_pickup"
                                ? buttonStyle.color
                                : "",
                            fontSize: "0.9rem",
                          }}
                        ></i>
                        <div> Curbside Pickup </div>
                      </Button>
                    )}

                    {(Object.keys(selected_store).length === 0 ||
                      storeHasAvailableMealPlan(selected_store)) && (
                      <Button
                        style={{
                          height: "70px",
                          width: "90px",
                          borderColor: "transparent",
                          backgroundColor:
                            this.state.values.order_type === "delivery" &&
                            this.state.values.order_subtype === "meal_plan"
                              ? buttonStyle.backgroundColor
                              : "lightgray",
                          color:
                            this.state.values.order_type === "delivery" &&
                            this.state.values.order_subtype === "meal_plan"
                              ? buttonStyle.color
                              : "",
                          fontSize: "0.9rem",
                        }}
                        className="flex-fill align-items-center justify-content-center btn mx-1 my-5 pa-0"
                        onClick={() =>
                          actions.handleOnClickOrderType(
                            "delivery",
                            "meal_plan",
                          )
                        }
                        disabled={Object.keys(selected_store).length === 0}
                      >
                        <i
                          className="fas fa-calendar-check"
                          style={{
                            color:
                              this.state.values.order_type === "delivery" &&
                              this.state.values.order_subtype === "meal_plan"
                                ? buttonStyle.color
                                : "",
                            fontSize: "0.9rem",
                          }}
                        ></i>
                        <div> Meal Plan </div>
                      </Button>
                    )}
                  </div>
                </Form.Group>
                <Form.Group>
                  <Label
                    text={
                      <>
                        <span className="text-capitalize">Order Time</span>{" "}
                        {stores.length > 1 && <RequiredAsterisk />}
                      </>
                    }
                  />

                  <Nav
                    variant="pills"
                    className="w-100 nav-justified"
                    activeKey={key}
                    onSelect={(key) => this.setState({ key })}
                  >
                    <Nav.Item className="mx-1">
                      <Nav.Link
                        className="justify-content-center"
                        eventKey="asap"
                        onClick={() => this.onChangeAsap("asap")}
                        disabled={isAsapDisabled}
                        style={{
                          borderColor: "transparent",
                          backgroundColor:
                            key === "asap"
                              ? buttonStyle.backgroundColor
                              : "lightgray",
                          color: key === "asap" ? buttonStyle.color : "white",
                          padding: "1rem 1.2rem",
                          fontSize: "1rem",
                        }}
                      >
                        ASAP
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="mx-1">
                      <Nav.Link
                        className="justify-content-center"
                        eventKey="preorder"
                        onClick={() => this.onChangeAsap("preorder")}
                        disabled={isPreOrderDisabled}
                        style={{
                          borderColor: "transparent",
                          backgroundColor:
                            key === "preorder"
                              ? buttonStyle.backgroundColor
                              : "lightgray",
                          color:
                            key === "preorder" ? buttonStyle.color : "white",
                          padding: "1rem 1.2rem",
                          fontSize: "1rem",
                        }}
                      >
                        Pre-Order
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Form.Group>
                {isOpenForOrders &&
                this.state.values.order_subtype !== "meal_plan" ? (
                  <div className="d-flex m-0">
                    {mountOrderDateDropdown ? (
                      <OrderDateDropdown
                        brand_url={brand_url}
                        store={selected_store}
                        inputActions={inputActions}
                        hasError={errors.hasOwnProperty("order_date")}
                        error={errors["order_date"]}
                        order_type={values["order_type"]}
                        value={values["order_date"]}
                        setOrderDate={(order_date) => {
                          let { values = {} } = this.state;
                          values.order_date = order_date;
                          this.setState({ values });
                        }}
                      />
                    ) : (
                      <></>
                    )}
                    {mountOrderTimeDropdown ? (
                      <OrderTimeDropDown
                        brand_url={brand_url}
                        store={selected_store}
                        inputActions={inputActions}
                        order_date={values["order_date"]}
                        hasError={errors.hasOwnProperty("order_time")}
                        error={errors["order_time"]}
                        order_type={values["order_type"]}
                        value={values["order_time"]}
                        no_asap={!this.state.isAsap}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  <></>
                )}

                {this.state.values.order_subtype === "meal_plan" ? (
                  <>
                    <Row>
                      <Col className="d-flex flex-column align-items-center">
                        {selected_store.mealPlanLabels &&
                          selected_store.mealPlanLabels.hasOwnProperty(
                            "3DayMealPlanTop",
                          ) && (
                            <div className="mt-2">
                              {selected_store.mealPlanLabels["3DayMealPlanTop"]}
                            </div>
                          )}
                        <Button
                          style={{
                            height: "70px",
                            width: "100px",
                            borderColor: "transparent",
                            backgroundColor:
                              this.state.values.meal_plan_type ===
                              "3DayMealPlan"
                                ? buttonStyle.backgroundColor
                                : "lightgray",
                          }}
                          className="btn mt-3"
                          onClick={() => this.handleOnClick3DayMealPlan()}
                        >
                          <i
                            style={{ color: button_text_color }}
                            className="far fa-calendar-check mr-3"
                          ></i>
                          3 DAY MEAL PLAN
                        </Button>
                        {selected_store.mealPlanLabels &&
                          selected_store.mealPlanLabels.hasOwnProperty(
                            "3DayMealPlanBtm",
                          ) && (
                            <div className="mt-2">
                              {selected_store.mealPlanLabels["3DayMealPlanBtm"]}
                            </div>
                          )}
                      </Col>
                      <Col className="d-flex flex-column align-items-center">
                        {selected_store.mealPlanLabels &&
                          selected_store.mealPlanLabels.hasOwnProperty(
                            "5DayMealPlanTop",
                          ) && (
                            <div className="mt-2">
                              {selected_store.mealPlanLabels["5DayMealPlanTop"]}
                            </div>
                          )}
                        <Button
                          style={{
                            height: "70px",
                            width: "100px",
                            borderColor: "transparent",
                            backgroundColor:
                              this.state.values.meal_plan_type ===
                              "5DayMealPlan"
                                ? buttonStyle.backgroundColor
                                : "lightgray",
                          }}
                          className="btn mt-3"
                          onClick={() => this.handleOnClick5DayMealPlan()}
                        >
                          <i
                            style={{ color: button_text_color }}
                            className="far fa-calendar-check mr-3"
                          ></i>
                          5 DAY MEAL PLAN
                        </Button>
                        {selected_store.mealPlanLabels &&
                          selected_store.mealPlanLabels.hasOwnProperty(
                            "5DayMealPlanBtm",
                          ) && (
                            <div className="mt-2">
                              {selected_store.mealPlanLabels["5DayMealPlanBtm"]}
                            </div>
                          )}
                      </Col>
                    </Row>
                  </>
                ) : (
                  <></>
                )}

                {this.props.order_type === "delivery" ? (
                  // <Row>
                  //     <Col>
                  //         <FormItem
                  //             label={"Delivery Address"}
                  //             name={"delivery_address"}
                  //             inputProps={{
                  //                 name: "delivery_address",
                  //                 placeholder: "Delivery Address",
                  //                 value: this.state.values["delivery_address"],
                  //                 rows: 2
                  //             }}
                  //             inputActions={inputActions}
                  //             actions={actions}
                  //             type={"textarea"}
                  //             normal
                  //             showRequired={true}
                  //         />
                  //         {/* <AutoCompleteAddress/> */}
                  //     </Col>
                  // </Row>
                  <></>
                ) : (
                  <></>
                )}
              </>
            ) : storeHasNoOrderType(selected_store) ? (
              <p>Store does not support any order fulfillment</p>
            ) : !isOpenForOrders ? (
              <p>Store is not open for pre-ordering</p>
            ) : (
              <p>Store is now closed for orders</p>
            )}
          </Modal.Body>
          {isOpenForOrders && (
            <Modal.Footer>
              <Button
                variant="outline-primary"
                type="submit"
                style={buttonStyle}
                disabled={
                  this.state.isSubmitting || isValuesNotEmpty(this.state.values)
                }
                className={GetSubmitClassList(
                  this.state.isSubmitting,
                  `br-0 btn-block font-weight-bolder mx-2`,
                )}
              >
                Proceed
              </Button>
            </Modal.Footer>
          )}
        </Form>
      </Modal>
    );

    function storeAsapDisabledCheck() {
      let isDisabled = false;
      // Check if store hours are not open
      if (!isStoreHoursOpen) {
        isDisabled = true;
      }
      // Check if no store is selected
      else if (!hasStoreSelected) {
        isDisabled = true;
      }
      // Check if a store is selected and the order subtype is "meal_plan"
      else if (hasStoreSelected && values.order_subtype === "meal_plan") {
        isDisabled = true;
      }
      // Check if there is no ASAP slot available
      else if (!isStoreHasAsapSlot) {
        isDisabled = true;
      }
      // All other cases
      else {
        isDisabled = false;
      }
      return isDisabled;
    }
    function storeCanServedCheck() {
      let canServe = false;
      if (!hasStoreSelected) {
        canServe = true;
      } else if (
        !storeHasNoOrderType(selected_store) &&
        isOpenForOrders &&
        storeHasTimeOptions()
      ) {
        canServe = true;
      }
      return canServe;
    }
    function storeHasTimeOptions() {
      if (values.order_subtype === "meal_plan") {
        return true;
      } else {
        return !isAsapDisabled || !isPreOrderDisabled;
      }
    }
  }
}

function isValuesNotEmpty(values) {
  const { store, order_subtype, order_date, order_time } = values;
  if (order_subtype === "meal_plan") return false;
  return hasEmptyString([store, order_date, order_time]);
}

function checkStoreDefaults(selected_store) {
  const hasMealPlan = storeHasAvailableMealPlan(selected_store);

  let defaultOrderType = "",
    defaultSubtype = "",
    defaultKey = "asap",
    defaultPlanType = "3DayMealPlan";

  if (hasMealPlan) {
    defaultOrderType = "delivery";
    defaultSubtype = "meal_plan";
  }
  if (storeHasCurbside(selected_store)) {
    defaultOrderType = "pickup";
    defaultSubtype = "curbside_pickup";
  }
  if (StoreHasDelivery(selected_store)) {
    defaultOrderType = "delivery";
    defaultSubtype = "delivery";
  }
  if (storeHasPickupActive(selected_store)) {
    defaultOrderType = "pickup";
    defaultSubtype = "pickup";
  }
  if (hasMealPlan && defaultSubtype === "meal_plan") {
    defaultKey = "preorder";
  }
  if (!selected_store.is_store_hours_open) {
    defaultKey = "preorder";
  }
  if (!selected_store.is_store_has_asap_slot) {
    defaultKey = "preorder";
  }

  return {
    defaultOrderType,
    defaultSubtype,
    defaultPlanType,
    defaultKey,
  };
}

function storeHasAvailableMealPlan(selected_store) {
  const hasActiveWalletDelivery = CountActive(
    selected_store.delivery_method,
  ).countWallet;
  const hasMealPlan =
    storeHasMealPlan(selected_store) && hasActiveWalletDelivery > 0;
  return hasMealPlan;
}

function storeHasNoOrderType(selected_store) {
  return (
    !StoreHasDelivery(selected_store) &&
    !storeHasPickupActive(selected_store) &&
    !storeHasCurbside(selected_store) &&
    !storeHasAvailableMealPlan(selected_store)
  );
}

function DisplayErrorMessage({ isOpenForOrders }) {
  return !isOpenForOrders ? (
    <WarningAlert
      message={<>Sorry, this store is closed. Please select another one.</>}
    />
  ) : (
    <></>
  );
}

function hasEmptyString(val) {
  for (var i in val) {
    if (val[i] === "") return true;
  }
  return false;
}
